import React, { FC } from "react"
import SEO from "../seo/SEO"
import style from "./review.module.scss"

interface reviewProps {
  message: string
  author: string
  date_reviewed?: string
  page_title?: string
  featured_image?: any
  rating?: string
}

const Review: FC<reviewProps> = ({ message, author, date_reviewed, page_title, featured_image, rating}) => {
  return (
    <>
      <SEO
        lang="en"
        title = ''
        description= ''
        image=  ''
        keywords=  ''
        meta=  ''
        schemaMarkup={
          { 
          "@context": "https://schema.org/",
            "@type": "Review",
            "author": `${author}`,
            "datePublished": `${date_reviewed}`,
            "name": `Review of ${page_title}`,
            "reviewBody": `${message}`,
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": `${rating}`
            },
            "itemReviewed": {
              "@type": "Product",
              "image": `${featured_image && featured_image.filename}`,
              "name": `${page_title}`,
              "brand": "OCD - Odour Control Developments"
            },
          }
        }
      />
      <div className={style.review}>
        <p className={style.message}>"{message}"</p>
        <p className={style.author}>{author}</p>
      </div>
    </>
  )
}

export default Review
