import React, { useEffect, useState } from "react"
import { motion } from "framer-motion"
import { FC, useContext } from "react"
import { ProductContext } from "../product-page-content/productPageContent"
import style from "./productImages.module.scss"
import TypeImages from "./typeImages"

// Props for flavour images
interface flavourImagesProps {
  flavour: any
}

// Animation values
const flavourImagesAnimations = {
  hidden: {
    opacity: 0,
  },
  left: {
    opacity: 0,
    x: "-100%",
  },
  center: {
    opacity: 1,
    x: 0,
  },
  right: {
    opacity: 0,
    x: "100%",
  },
}

const fragranceImages: FC<flavourImagesProps> = ({ flavour }) => {

  // Extract types from the flavour prop
  const { variants, index } = flavour

  //   Get the active flavour from context
  const { activeFragranceIndex } = useContext(ProductContext)

  // Guard clause to ensure context is defined
  if (typeof activeFragranceIndex === "undefined") return null

  // Hold the fragrence images position in local state
  const [position, setPosition] = useState<null | string>(null)

  useEffect(() => {  
    //   if current type is active center the image
    if (index === activeFragranceIndex) {
      setPosition("center")
      return
    }

    // Set position in local state
    setPosition(index > activeFragranceIndex ? "right" : "left")
  }, [activeFragranceIndex])

  // Guard clause to ensure position is set
  if (!position) return null

  return (
    <motion.div
      className={style.flavour}
      variants={flavourImagesAnimations}
      // initial="hidden"
      animate={position}
    >
      {variants.map(({ type, image, _uid }:any) => (
        type &&
        <TypeImages typeName={type.name} typeImage={image} key={`typeImages_${_uid}`} />
      ))}
    </motion.div>
  )
}

export default fragranceImages
