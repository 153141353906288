import React, { FC, ReactNode } from "react"
import style from "./modal.module.scss"
import exitIcon from "../../../images/exit.svg"

// Modal props
interface modalProps {
  active: boolean
  children: ReactNode
  onClose: () => void
  icon?: {
    url?: string
    alt?: string
  }
}

// Modal component
const Modal: FC<modalProps> = ({ active, children, onClose, icon }) => {
  return !active ? null : (
    <div className={style.modalContainer}>
      {/* Scrim background, closes modal on click */}
      <div className={style.scrim} onClick={onClose} />
      {/* Wrap modal to enable padding */}
      <div className={style.modalWrapper}>
        <div className={style.modal}>
          {/* Add an icon if set */}
          {icon && (
            <div className={style.iconWrapper}>
              <img src={icon?.url} alt={icon?.alt} />
            </div>
          )}
          <div className={style.content}>{children}</div>
          {/* Close modal button */}
          <button
            onClick={onClose}
            className={style.close}
            aria-label="Close modal"
          >
            <img src={exitIcon} alt="Close modal button" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Modal
