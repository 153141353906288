import React from "react"
import { createContext } from "react"
import { hintButton } from "../../../../../types/hint"
import LinkButton from "../../../utilities/buttons/link-button/linkButton"
import Button from "../../../utilities/buttons/primary-button/primaryButton"
import Modal from "../../../utilities/modal/modal"
import RichText from "../../../utilities/rich-text/richText"
import ProductImages from "../product-images/productImages"
import {
  productHookReturns,
  useProducts,
} from "../product-page-hooks/useProducts"
import ProductSelectors from "../product-selectors/productSelectors"
import style from "./productPageContext.module.scss"

// Match context type to product hook
type productContextTypes = productHookReturns

// initialize empty product context
export const ProductContext = createContext<Partial<productContextTypes>>({})

const ProductPageContent = ({ data }: any) => {
  // get product functionality
  const products = useProducts(data.allStoryblokEntry.nodes)

  const { unavilableMessage, setUnavailableMessage, setActiveType } = products

  return (
    <ProductContext.Provider value={products}>
      <div className={style.content}>
        <div className={style.intro}>
          <h1>OCD odour neutraliser range</h1>
          <p>Odour neutralisers to keep your life smelling beautiful.</p>
        </div>
        <ProductImages />
        <ProductSelectors />
        <Modal
          key={`unavailableTypeModal_${
            unavilableMessage ? unavilableMessage._uid : ""
          }`}
          active={unavilableMessage !== null}
          onClose={() => setUnavailableMessage(null)}
          icon={
            unavilableMessage?.icon?.filename &&
            unavilableMessage?.icon?.filename.length > 0
              ? {
                  url: unavilableMessage.icon.filename,
                  alt: unavilableMessage.icon.alt,
                }
              : undefined
          }
        >
          <h2>{unavilableMessage?.title}</h2>
          <RichText body={unavilableMessage?.message} />
          {unavilableMessage?.button &&
            unavilableMessage.button.length > 0 &&
            unavilableMessage?.button.map((btn: hintButton) =>
              btn.button_link.cached_url ? (
                <LinkButton
                  key={`modalButton_${btn._uid}`}
                  href={btn.button_link.cached_url}
                >
                  {btn?.button_text}
                </LinkButton>
              ) : (
                <Button
                  key={`modalButton_${btn._uid}`}
                  color="white"
                  center
                  onClick={() => {
                    setUnavailableMessage(null)
                    setActiveType("gel")
                  }}
                  className={style.modalButton}
                >
                  {btn.button_text}
                </Button>
              )
            )}
        </Modal>
      </div>
    </ProductContext.Provider>
  )
}

export default ProductPageContent
