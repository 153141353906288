import { useEffect, useState } from "react"
import { hint } from "../../../../../types/hint"

interface useHintsProps {
  products: null | Array<any>
  activeFragranceIndex: number
  activeType: null | string
}

export const useHints = ({
  products,
  activeFragranceIndex,
  activeType,
}: useHintsProps): hint[] => {
  // Hold active list of hints in local state
  const [hints, setHints] = useState<hint[]>([])

  useEffect(() => {
    if (products) {
      // Get active fragrance
      const activeFragrance = products[activeFragranceIndex]
      // Get current active type
      const currentType = activeFragrance.variants
        .filter((variant: any) => variant.type?.name?.toLowerCase() === activeType)[0]
      
      // Set hints in local state
      if(typeof currentType !== 'undefined') {
        setHints([...activeFragrance.hints, ...currentType.hints])
      }
      // setHints([...activeFragrance.hints, ...currentType.hints])
    }
  }, [activeFragranceIndex, activeType, products])

  return hints
}
