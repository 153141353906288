import React, { useContext, useEffect, useState } from "react"
import { fragranceVariant } from "../../../../../../types/fragrance"
import { ProductContext } from "../../product-page-content/productPageContent"
import NewTag from "../../../../misc/new-tag/newTag"
import Selector from "../selector/selector"
import style from "./typeSelectors.module.scss"

const TypeSelectors = () => {
  const {
    activeType,
    activeFragrance,
    setActiveType,
    types,
    setUnavailableMessage,
  } = useContext(ProductContext)

  // Guard clause for undefined context
  if (
    !setActiveType ||
    !activeType ||
    !activeFragrance ||
    !types ||
    !setUnavailableMessage
  )
    return null

  // Hold unavailable types in local state
  const [unavailableTypes, setUnavailableTypes] = useState<fragranceVariant[]>(
    []
  )

  useEffect(() => {
    const unavailableVariants = activeFragrance.variants.filter(
      (variant: fragranceVariant) => !variant.available
    )
    setUnavailableTypes(unavailableVariants)
  }, [activeFragrance])



  useEffect(() => {
    // Check if unavailable
    const unavailable = getUnavailableType(activeType)
    if (unavailable) {
      if (unavailable.unavailable_message[0])
        setUnavailableMessage(unavailable.unavailable_message[0])
    }
  }, [unavailableTypes])

  // Handle clicking a type selector
  const handleTypeClick = (type: string) => {
    setActiveType(type)

    // Check if unavailable
    const unavailable = getUnavailableType(type)

    if (unavailable) {
      if (unavailable.unavailable_message[0])
        setUnavailableMessage(unavailable.unavailable_message[0])
    }
  }

  //   function to check if unavailable
  const getUnavailableType = (type: string) => {
    const toReturn = unavailableTypes
      // @ts-ignore
      .filter((variant: fragranceVariant) => variant?.type?.name?.toLowerCase() === type)
    return toReturn.length > 0 ? toReturn[0] : null
  }

  return (
    <>
      <div className={style.types}>
        <h4 className={style.title}>Types</h4>
        <div className={style.selectors}>
          {types.map(({ name, slug, icon, show_as_new }: any) => (
            <Selector
              key={`typeSelector_${slug}`}
              onClick={() => handleTypeClick(slug)}
              active={activeType === slug}
              // Apply unavailable style if unavailable
              className={getUnavailableType(slug) ? style.unavailable : ""}
            >
              <img src={icon.filename} alt={icon.alt} className={style.icon} />
              {`${name}${getUnavailableType(slug) ? " Unavailable" : ""}`}
              {show_as_new && !getUnavailableType(slug) && <NewTag />}
            </Selector>
          ))}
        </div>
      </div>
    </>
  )
}

export default TypeSelectors
