import { graphql } from "gatsby"
import React from "react"
import ProductPageContent from "../../components/page-specific/product-page-components/product-page-content/productPageContent"
import ProductsExtra from "../../components/page-specific/product-page-components/product-seo-content/productsExtra"
import SEO from "../../components/utilities/seo/SEO"
import Layout from "../../layout/layout"
import style from "./productsPage.module.scss"

const ProductsPage = ({ data }: any) => {
  return (
    <Layout
      title="Products"
      darkMode
      className={`${style.productsPage} `}
      desktopHeader
    >
      <SEO
        lang="en"
        title="Odour neutraliser - Professional odour eliminator | OCD"
        description="The best professional odour neutralisers for any situation. Eliminate bad odours with the OCD range."
        image="https://ocdonline.co.uk/images/ocd/odour-neutraliser-products.png"
        keywords="Odour neutraliser, Odour eliminators, Buy odour eliminators, Buy odour neutralisers, Find odour neutraliser online, odour neutraliser products, odour neutraliser spray"
      />
      <div className={style.productsContent}>
        <ProductPageContent data={data} />
      </div>
      <ProductsExtra />
    </Layout>
  )
}

export default ProductsPage

// Get  fragrances from CMS
export const query = graphql`
  query MyQuery {
    allStoryblokEntry(filter: { field_component: { eq: "fragrance" } }) {
      nodes {
        name
        slug
        content
      }
    }
  }
`
