import React, { useContext, useState } from "react"
import { hint } from "../../../../../types/hint"
import { ProductContext } from "../product-page-content/productPageContent"
import Modal from "../../../utilities/modal/modal"
import RichText from "../../../utilities/rich-text/richText"
import style from "./productHints.module.scss"

const ProductHints = () => {
  // Extract hints from the context
  const { hints } = useContext(ProductContext)
  // Hold the active hint within local state
  const [activeHint, setActiveHint] = useState<hint | null>(null)

  //   Click handler for selectors
  const handleClick = (hint: hint) => {
    setActiveHint(hint)
  }

  return !hints ? null : (
    <>
      <div className={style.hints}>
        {hints.map((hint: any) => (
          <button
            onClick={() => handleClick(hint)}
            key={`productHint_${hint._uid}`}
            className={style.hintSelector}
          >
            {hint.title}
          </button>
        ))}
      </div>
      <Modal
        active={Boolean(activeHint)}
        onClose={() => setActiveHint(null)}
        key={activeHint?._uid || ""}
        icon={
          activeHint?.icon.filename
            ? { url: activeHint?.icon.filename, alt: activeHint?.icon.alt }
            : undefined
        }
      >
        <h2>{activeHint?.title}</h2>
        <RichText body={activeHint?.message} />
      </Modal>
    </>
  )
}

export default ProductHints
