import React, { useContext } from "react"
import LinkButton from "../../../utilities/buttons/link-button/linkButton"
import { ProductContext } from "../product-page-content/productPageContent"
import style from "./productSelectors.module.scss"
import FragranceSelector from "./selector/fragranceSelector"
import TypeSelectors from "./type-selectors/typeSelectors"

// Component to handle product/ flavour selection and type selection
const ProductSelectors = () => {
  const { products, setActiveType, activeType } = useContext(ProductContext)

  // Guard clause for no products / not set
  if (!products || !setActiveType || !activeType) return null

  return (
    <div className={style.selectorsContainer}>
      {/* Flagrance Selector */}
      <div className={style.intro}>
        <h1>OCD odour neutraliser range</h1>
        <p>Odour neutralisers to keep your life smelling beautiful.</p>
      </div>
      <div className={style.fragrances}>
        <h4 className={style.title}>Fragrances</h4>
        <div className={style.selectors}>
          {products.map(fragrance => (
            <FragranceSelector
              key={`fragranceSelector_${fragrance.slug}`}
              fragrance={fragrance}
            />
          ))}
        </div>
      </div>

      {/* Type selector */}
      <TypeSelectors />

      <LinkButton
        href="/retailers"
        color="white"
        className={style.desktopBtnWidth}
      >
        Find a Retailer
      </LinkButton>
    </div>
  )
}

export default ProductSelectors
