import { motion } from "framer-motion"
import React, { FC, useContext } from "react"
import { ProductContext } from "../../product-page-content/productPageContent"
import NewTag from "../../../../misc/new-tag/newTag"
import Selector from "./selector"
import style from "./selector.module.scss"

// Fragrance selector props
interface fragranceSelectorProps {
  fragrance: any
}

// Function to set background color
const setBgColor = (color: string, active: boolean) => {
  // Check if color isnt set or button isnt activve
  if (!color || !active) {
    return undefined
  }
  // If colour is set and button is active change background color
  return { background: color, color: "#000" }
}

// Fragrance selector component
const FragranceSelector: FC<fragranceSelectorProps> = ({ fragrance }) => {
  // Extract active fragrance from context
  const { activeFragranceIndex, setActiveFragranceIndex } = useContext(
    ProductContext
  )

  //   Guard clause for if context is not set
  if (
    typeof activeFragranceIndex === "undefined" ||
    typeof setActiveFragranceIndex === "undefined"
  )
    return null

  // extract nessasary data from fragrance
  const { name, color, index, new: showAsNew } = fragrance

  //   Hold active state within a local variable
  const isActive = index === activeFragranceIndex

  //   Handle click event (set fragrance as active)
  const handleClick = () => {
    setActiveFragranceIndex(index)
  }

  return (
    <Selector
      onClick={handleClick}
      style={setBgColor(color.color, isActive)}
      active={isActive}
    >
      {name}
      {showAsNew && <NewTag />}
    </Selector>
  )
}

export default FragranceSelector
