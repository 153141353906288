import React, { FC } from "react"
import LinkButton from "../../../utilities/buttons/link-button/linkButton"
import style from "./productsExtra.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import { productType } from "../../../../../types/fragrance"
import slugify from "slugify"

const ProductsExtraWrapper = () => {
  // Query for the product types
  const {
    allStoryblokEntry: { nodes },
  } = useStaticQuery(graphql`
    {
      allStoryblokEntry(filter: { field_component: { eq: "product_type" } }) {
        nodes {
          content
          name
        }
      }
    }
  `)

  const types = nodes.map(({ content, name }: any) => {
    const jsonContent = JSON.parse(content)
    // slugify the page title
    const slug = slugify(jsonContent.page_title || "", {
      lower: true,
      strict: true,
    })
    return {
      name,
      ...jsonContent,
      slug,
    }
  })

  return <ProductsExtra types={types} />
}

// Set up props for component
interface productsExtraProps {
  types: productType[]
}

const ProductsExtra: FC<productsExtraProps> = ({ types }) => (
  <div className={style.content}>
    <div className={style.extraContent}>
      <h2>Learn more about our odour neutraliser products</h2>

      <div className={style.learnAbout}>
        {types.map(product => (
          <div className={style.type} key={`learnAbout_${product.slug}`}>
            {product.icon && product.icon.filename && (
              <img src={product.icon.filename} alt={product.icon.alt} />
            )}
            <h3>{product.name}</h3>
            <p>{product.short_description}</p>
            <LinkButton
              color="white"
              href={`/odour-neutraliser-products/${product.slug}/`}
            >
              Explore {product.page_title}
            </LinkButton>
          </div>
        ))}
      </div>
    </div>
  </div>
)

export default ProductsExtraWrapper
