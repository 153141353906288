import { graphql, useStaticQuery } from "gatsby"
import { productType } from "../../../../../types/fragrance"

export const useGetTypes = (): productType[] | null => {
  // Query for product types from the CMS
  const typeQuery = useStaticQuery(graphql`
    {
      allStoryblokEntry(filter: { field_component: { eq: "product_type" } }) {
        types: nodes {
          content
          slug
          name
        }
      }
    }
  `)
  // Extract the types from the query
  const {
    allStoryblokEntry: { types },
  } = typeQuery

  // Guard clause for no types
  if (!types || types.length === 0) return null

  // Ensure content is JSON
  const typesData = types.map(({ content, ...rest }: any) => ({
    ...JSON.parse(content),
    ...rest,
  }))
  
  return typesData
}
