import React, { useContext } from "react"
import { ProductContext } from "../product-page-content/productPageContent"
import ProductHints from "../product-hints/productHints"
import FlavourImages from "./fragranceImages"
import style from "./productImages.module.scss"
import { fragrance } from "../../../../../types/fragrance"

// get active product background color
const getBgColor = (products: fragrance[], activeIndex: number) => {
  // get active product using the active index
  const activeProduct = products[activeIndex]

  // Extract background colour from product
  const { background_colour } = activeProduct

  return background_colour
    ? { backgroundColor: background_colour.color }
    : undefined
}

const ProductImages = () => {
  // Extract list of products from context
  const { products, activeFragranceIndex } = useContext(ProductContext)

  //   Gu1ard clause for no products or index
  if (!products || typeof activeFragranceIndex === "undefined")
    return <div className={style.gallery} />

  return (
    <div
      className={style.gallery}
      style={getBgColor(products, activeFragranceIndex)}
    >
      {/* Map over fragrances */}
      {products.map(fragrance => (
        //   Render flavour images component for each fragrance / product
        <FlavourImages
          flavour={fragrance}
          key={`flavourImages_${fragrance.slug}`}
        />
      ))}

      {/* Add hints */}
      <ProductHints />
    </div>
  )
}

export default ProductImages
