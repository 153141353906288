import {
  useState,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction,
} from "react"
import { fragrance, productType } from "../../../../../types/fragrance"
import { hint } from "../../../../../types/hint"
import { LayoutContext } from "../../../../layout/layout"
import { useGetTypes } from "./getTypes"
import { useHints } from "./useHints"

export interface productHookReturns {
  activeType: null | string
  setActiveType: (value: string) => void
  activeFragranceIndex: number
  setActiveFragranceIndex: (value: number) => void
  products: null | fragrance[]
  hints: hint[]
  activeFragrance: fragrance | null
  types: productType[] | null
  unavilableMessage: hint | null
  setUnavailableMessage: Dispatch<SetStateAction<hint | null>>
}

// Hold all of the global product functionality in this hook
export const useProducts = (productData: any): productHookReturns => {
  // Extract required data from layout context
  const { setHeaderColor } = useContext(LayoutContext)
  // Hold active fragrance in local state
  const [activeFragranceIndex, setActiveFragranceIndex] = useState<number>(0)
  // Hold active type in local state
  const [activeType, setActiveType] = useState<null | string>("gel")
  // Hold list of products in local state
  const [products, setProducts] = useState<null | any>(null)
  // Get active product
  const activeFragrance: fragrance | null = products
    ? products[activeFragranceIndex]
    : null

  // Get the list of types using the types hook
  const types = useGetTypes()

  // console.log("types", types)

  // Get list of hints subject to the current variant
  const hints = useHints({ products, activeType, activeFragranceIndex })

  // Hold unavailable message in state (to be passed up tree)
  const [unavilableMessage, setUnavailableMessage] = useState<hint | null>(null)

  useEffect(() => {
    // Parse storyblok content string to json and hold within state
    const productJson = productData.map(
      ({ content, name, slug }: any, index: number) => ({
        ...JSON.parse(content),
        name,
        slug,
        index: index,
      })
    )
    setProducts(productJson)
  }, [])

  const handleSetFragrance = (value: number) => {
    // Guard clause for no products
    if (!products) return
    // Check not at max or min
    if (value < 0 || value >= products.length) return
    // otherwise set fragrance state
    setActiveFragranceIndex(value)
  }

  const handleSetType = (value: string) => {
    // Guard clause for no products
    if (!products) return
    // Check not at max or min
    if (!value) return
    // otherwise set fragrance state
    setActiveType(value)
  }

  useEffect(() => {
    // Guard clause for defined context
    if (!setHeaderColor || !activeFragrance) return

    // set header colour to fragrance colours
    setHeaderColor(activeFragrance?.background_colour?.color || null)
  }, [activeFragrance])

  return {
    activeFragranceIndex,
    setActiveFragranceIndex: handleSetFragrance,
    products,
    activeType,
    setActiveType: handleSetType,
    hints,
    activeFragrance,
    types,
    unavilableMessage,
    setUnavailableMessage,
  }
}
