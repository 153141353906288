import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage, { FluidObject } from "gatsby-image"
import React, { FC } from "react"

interface fallbackImgProps {
  url?: FluidObject
  alt?: string
}

const FallbackImg: FC<fallbackImgProps> = ({ url, alt }) => {
  const image = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "OCD Empty Packaging.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <GatsbyImage
      fluid={url || image.file.childImageSharp.fluid}
      alt={alt || "OCD Fallback images, all packaging empty"}
    />
  )
}

export default FallbackImg
