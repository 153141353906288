import { Link } from "gatsby"
import React, { FC } from "react"
import {
  render,
  MARK_LINK,
  // @ts-ignore
} from "storyblok-rich-text-react-renderer"
import ImageWithCaption from "../image-with-caption/imageWithCaption"
import Review from "../review/review"
import style from "./richText.module.scss"

interface richText {
  body: any
  className?: string
  page_title?: string
  featured_image?: any
}

const RichText: FC<richText> = ({ body, className, page_title, featured_image }) => {
  return (
    <div className={`${style.richText} ${className || ""}`}>
      {render(body, {
        blokResolvers: {
          ["image_with_caption"]: (props: any) => (
            <ImageWithCaption {...props} />
          ),
          ["review"]: (props: any) => <Review {...props} page_title={page_title} featured_image={featured_image}/>,
        },
        markResolvers: {
          [MARK_LINK]: (children: React.ReactNode, { href, linktype }: any) =>
            linktype === "story" ? (
              <Link to={href} style={{ marginRight: "32px" }}>
                {children}
              </Link>
            ) : (
              <a href={href} target="_blank" rel="norefferer noopener">
                {children}
              </a>
            ),
        },
      })}
    </div>
  )
}

export default RichText
