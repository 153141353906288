import React, { FC } from "react"
import style from "./imageWithCaption.module.scss"
import { storyblokImage } from "../../../../types/storyblok"

interface imageWithCaptionProps {
  image: storyblokImage
  title: string
  body: string
  direction: string
}

const ImageWithCaption: FC<imageWithCaptionProps> = ({
  title,
  body,
  image,
  direction,
}) => {
  return (
    <div
      className={`${style.wrapper} ${
        direction === "rtl" ? style.reversed : ""
      }`}
    >
      <div className={style.imgWrapper}>
        {image && image.filename && (
          <img src={image.filename} alt={image.alt} />
        )}
      </div>
      <div className={style.content}>
        <h2 className={style.title}>{title}</h2>
        <p className={style.body}>{body}</p>
      </div>
    </div>
  )
}

export default ImageWithCaption
