import React, { ButtonHTMLAttributes, FC } from "react"
import style from "./selector.module.scss"

// Selector props
interface selectorProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  active: boolean
}

// Selector button for products page
const Selector: FC<selectorProps> = ({ className, active, ...props }) => {
  return (
    <button
      className={`${style.selector} ${active ? style.active : ""} ${className ||
        ""}`}
      {...props}
    />
  )
}

export default Selector
