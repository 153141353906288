import { motion } from "framer-motion"
import GatsbyImage from "gatsby-image"
import React, { FC, useContext, useEffect, useState } from "react"
import { ProductContext } from "../product-page-content/productPageContent"
import style from "./productImages.module.scss"
// @ts-ignore
import { getFluidGatsbyImage } from "gatsby-storyblok-image"
import FallbackImg from "../../../misc/fallback-img/fallbackImg"

// Type images props
interface typeImagesProps {
  typeName: string,
  typeImage: any
}

// Type images animation
const typeImagesAnimations = {
  hidden: {
    opacity: 0,
    y: "100%",
  },
  below: {
    opacity: 0,
    y: "100%",
  },
  above: {
    opacity: 0,
    y: "-100%",
  },
  center: {
    opacity: 1,
    y: 0,
  },
}

const TypeImages: FC<typeImagesProps> = ({ typeName, typeImage }) => {
  // Extract the active type from context
  const {
    activeType,
    setActiveType,
    activeFragranceIndex,
    setActiveFragranceIndex,
    types,
  } = useContext(ProductContext)

  // Guard clause to ensure context is defined
  if (
    !activeType ||
    typeof activeFragranceIndex === "undefined" ||
    !setActiveFragranceIndex ||
    !setActiveType ||
    !types
  )
    return null

  // Prepare image variants for gatsby image
  const image = getFluidGatsbyImage(typeImage.filename, { width: 900 })

  //   Hold posiiton within local state
  const [position, setPosition] = useState<null | string>(null)

  useEffect(() => {
    if(!typeName) return;
    // if current type is active center the image
    // console.log('typename:', typeName)
    // console.log('activeType:', activeType)
    if (typeName.toLocaleLowerCase() === activeType) {
      setPosition("center")
      return
    }

    // Check current index against active to see position
    const currentOrderPosition = types.map(({ slug }) => slug).indexOf(typeName)
    const activeOrderPosition = types
      .map(({ slug }) => slug)
      .indexOf(activeType)

    // Set position in local state
    setPosition(currentOrderPosition > activeOrderPosition ? "below" : "above")
  }, [activeType])

  // Handle drag ending, changing type & fragrance
  // @ts-ignore
  const handleDragEnd = (event: any, info: any) => {
    // Extract the offset positions from info
    const { offset } = info
    // Extract x & y from offset
    const { x } = offset

    // Change image depending on swipe direction
    if (x > 70) {
      setActiveFragranceIndex(activeFragranceIndex - 1)
    } else if (x < -70) {
      setActiveFragranceIndex(activeFragranceIndex + 1)
    }
  }

  //   Guard clause ensuring a position is set
  if (!position) return null

  // Get the current types data
  const currentType = types.filter(({ slug }) => slug === activeType)[0]
  const currentTypeFallbackImage = getFluidGatsbyImage(
    currentType.fallback_image.filename,
    { width: 900 }
  )

  return (
    <motion.div
      className={style.type}
      variants={typeImagesAnimations}
      initial="hidden"
      animate={position}
    >
       {/* Enable dragging functionality on image wrapper to scroll through fragrances and variants   */}
      <motion.div
        drag="x"
        dragDirectionLock
        dragElastic={1}
        dragConstraints={{ left: 0, top: 0, bottom: 0, right: 0 }}
        onDragEnd={handleDragEnd}
        className={style.imgWrapper}
      >
        {image ? (
          <GatsbyImage fluid={image} />
        ) : (
          <FallbackImg
            url={currentTypeFallbackImage}
            alt={currentType.fallback_image.alt}
          />
        )}
      </motion.div>
    </motion.div> 
  )
}

export default TypeImages
